$gradient-visible-sm-size: 15%;
$gradient-visible-md-size: 35%;

$left-gradient-sm: linear-gradient(to right, rgb(0 0 0 / 0%) 0, rgb(0 0 0) $gradient-visible-sm-size);
$right-gradient-sm: linear-gradient(to left, rgb(0 0 0 / 0%) 0, rgb(0 0 0) $gradient-visible-sm-size);

$left-gradient-md: linear-gradient(to right, rgb(0 0 0 / 0%) 0, rgb(0 0 0) $gradient-visible-md-size);
$right-gradient-md: linear-gradient(to left, rgb(0 0 0 / 0%) 0, rgb(0 0 0) $gradient-visible-md-size);

.horizontal-scroll {
  &--gradient-right-sm {
    mask-image: $right-gradient-sm;
  }

  &--gradient-left-sm {
    mask-image: $left-gradient-sm;
  }

  &--gradient-right-md {
    mask-image: $right-gradient-md;
  }

  &--gradient-left-md {
    mask-image: $left-gradient-md;
  }
}
