@import '@teliads/components/foundations/spacing/variables';

%side-modal-horizontal-padding {
  padding-left: $telia-spacing-16;
  padding-right: $telia-spacing-16;
}

%center-modal-horizontal-padding {
  padding-left: $telia-spacing-24;
  padding-right: $telia-spacing-24;
}
