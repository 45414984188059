@import '@teliads/components/foundations/colors/variables';

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: $telia-transparent-black-500;
}
