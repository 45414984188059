@import '@teliads/components/foundations/spacing/variables';
@import "@glidejs/glide/src/assets/sass/glide.core";

.glide__slides {
  white-space: normal;
}

.glide__arrows {
  display: flex;
  justify-content: flex-end;
  gap: $telia-spacing-8;
}

.glide-container {
    &__title {
      display: flex;
      align-items: center;
    }
}
