@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

.card {
  width: 100%;
  padding: $telia-spacing-24 $telia-spacing-16;
  border-radius: $telia-spacing-16;
  background-color: $telia-white;

  @include telia-breakpoint('lg') {
    padding: $telia-spacing-32 $telia-spacing-24;
  }
}
