@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

.step-summary {
  &__main-info {
    margin-bottom: $telia-spacing-16;
  }

  &__divider {
    display: block;
    margin-bottom: $telia-spacing-8;
    margin-top: calc($telia-spacing-12 * -1);
  }
}

.summary-list {
  &:not(:last-of-type) {
    margin-bottom: $telia-spacing-48;
  }
}

.summary-list-item {
  padding: calc($telia-spacing-4 + $telia-spacing-2) $telia-spacing-16;
  width: 100%;

  &:nth-of-type(even) {
    background-color: $telia-gray-50;
  }

  &__paragraph {
    margin-bottom: 0px !important;

    @include telia-breakpoint('md') {
      flex: 1;
    }
  }

  @include telia-breakpoint('md') {
    display: flex;
    justify-content: space-between;
  }
}

.summary-section-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
