@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/borders/mixins';

.document-asset {
    display: flex;
    align-items: center;
    gap: $telia-spacing-12;
    padding: $telia-spacing-12 0;
    @include telia-border(gray-200, xs, bottom);
}

