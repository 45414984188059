@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';

$remove-telia-button-margin: -14px;

.mobile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $telia-white;
  height: $telia-spacing-64;
  padding-left: $telia-spacing-16;

  &__icons {
    display: flex;
  }

  &__menu-button {
    padding: $telia-spacing-4;
  }

  &__back-button {
    margin-left: $remove-telia-button-margin;
  }

  &__search {
    padding: $telia-spacing-16;
  }
}
