@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

.step-three-form {
  &__main-info {
    margin-bottom: $telia-spacing-16;
  }

  &__divider {
    display: block;
    margin-bottom: $telia-spacing-24;
    margin-top: calc($telia-spacing-12 * -1);
  }
}
