@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/colors/variables';

.faq {
  margin-bottom: $telia-spacing-32;

  &__hero-banner {
    margin-bottom: $telia-spacing-32;
    color: $telia-white;
  }

  &__title {
    margin: $telia-spacing-16 0 $telia-spacing-32 0;
  }
}
