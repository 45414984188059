@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/borders/variables';
@import '@teliads/components/foundations/typography/variables';

.stepper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: $telia-spacing-16;
  margin-bottom: $telia-spacing-16;

  &__item {
    display: flex;
    align-items: center;
    font-weight: normal;
    margin-right: $telia-spacing-4;
  }

  &__line {
    width: $telia-spacing-12;
    height: $telia-spacing-1;
    background-color: $telia-gray-500;
    margin-right: $telia-spacing-12;
    margin-left: $telia-spacing-12;
  }

  &__circle {
    width: calc($telia-spacing-12 + $telia-spacing-12 + $telia-spacing-2);
    height: calc($telia-spacing-12 + $telia-spacing-12 + $telia-spacing-2);
    border-radius: $telia-border-radius-full;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: $telia-typography-weight-bold;
    font-size: 1rem;
    margin-right: $telia-spacing-4;

    &--active {
      background-color: $telia-purple-900;
      color: $telia-white;
    }

    &--completed {
      background-color: $telia-white;
      color: $telia-black;
      border: $telia-border-width-1 solid $telia-black;
    }

    &--disabled {
      background-color: $telia-gray-500;
      color: $telia-white;
    }
  }

  &__label {
    display: none;

    @include telia-breakpoint('md') {
      display: block;
      margin-right: $telia-spacing-4;
      margin-left: $telia-spacing-4;
      font-size: $telia-spacing-16;
      font-weight: $telia-typography-weight-medium;

      &--disabled {
        color: $telia-gray-500;
      }
    }
  }
}
