@import '@teliads/components/foundations/spacing/variables';

.map-container {
    position: relative;

    &__places-container {
        margin-bottom: $telia-spacing-24;
    }
}

.status-message {
    margin-bottom: $telia-spacing-24;
}