@import "@teliads/components/foundations/spacing/variables";
@import "@teliads/components/foundations/colors/tokens";
@import '@teliads/components/foundations/breakpoints/mixins';

.legal-row {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__legal-text {
    text-align: left;
  }

  &-consent-list {
    list-style: none;
    display: flex;
    align-content: space-between;
    flex-direction: row;

    &__item {
      margin-right: $telia-spacing-12;
    }
    &__item-value {
      font-weight: bold;
      color: $telia-purple-300;
    }
  }
}

.footer-content {
  color: $telia-transparent-white-900;
  gap: $telia-spacing-48;

  &__column {
    padding-right: $telia-spacing-32;
  }

  @include telia-breakpoint('lg') {
    gap: 0px;
  }

  &__section-title {
    margin-bottom: $telia-spacing-16;

    @include telia-breakpoint('lg') {
      margin-bottom: $telia-spacing-32;
    }
  }

  &__link {
    color: $telia-transparent-white-900;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
      text-underline-offset: $telia-spacing-2;
      color: $telia-purple-300;
    }
  }
}

.logo {
  width: calc($telia-spacing-80 + $telia-spacing-8)
}
