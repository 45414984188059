@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/borders/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import 'styles/breakpoints.scss';
@import 'styles/mixins.scss';

$landscape-aspect-ratio: calc(16 / 9);
$portrait-aspect-ratio: calc(4 / 5);
$fullscreen-aspect-ratio: calc(4 / 3);
$classic-tv-aspect-ratio: calc(5 / 4);
$envelope-aspect-ratio: calc(3 / 2);
$small-portrait-aspect-ratio: 0.75;
$square-aspect-ratio: 1;
$line-clamp-large: 4;
$line-clamp-medium: 3;
$line-clamp-small: 2;
$card-border-radius: $telia-border-radius-8;
$card-shading: linear-gradient(
  180deg,
  rgba(0, 0, 0, 0) 35.21%,
  rgba(0, 0, 0, 0.7) 67.19%,
  #000000 100%
);
$large-base-card-min-height: 288px;

.base-card {
  position: relative;
  border-radius: $card-border-radius;
  color: $telia-white;
  padding: $telia-spacing-16;
  width: 100%;
  display: flex;
  align-items: flex-end;
  overflow: hidden;

  &:not(.base-card__image) {
    background-color: $telia-black;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  @media (min-width: $custom-breakpoint-xsmall) {
    padding: $telia-spacing-24;
  }

  @include telia-breakpoint('md') {
    padding: $telia-spacing-32;
  }

  @include telia-breakpoint('lg') {
    padding: $telia-spacing-48;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    &::before {
      content: '';
      position: absolute;
      background: $card-shading;
      display: block;
      height: 120%;

      @include telia-breakpoint('md') {
        height: 100%;
      }
      width: 100%;
      left: 0;
      bottom: 0;
      border-radius: $card-border-radius;
      z-index: 1;
    }
  }

  &--large {
    height: 100%;
    min-height: $large-base-card-min-height;
    aspect-ratio: $square-aspect-ratio;

    @include telia-breakpoint('md') {
      aspect-ratio: $fullscreen-aspect-ratio;
    }

    @include telia-breakpoint('lg') {
      aspect-ratio: $landscape-aspect-ratio;
    }
  }

  &--medium {
    aspect-ratio: $classic-tv-aspect-ratio;

    @include telia-breakpoint('md') {
      aspect-ratio: $envelope-aspect-ratio;
    }
  }

  &--regular {
    aspect-ratio: $small-portrait-aspect-ratio;

    @include telia-breakpoint('md') {
      aspect-ratio: $classic-tv-aspect-ratio;
    }
  }

  &--secondary-regular {
    aspect-ratio: unset;
    height: 20rem;

    @include telia-breakpoint('lg') {
      aspect-ratio: $portrait-aspect-ratio;
      height: unset;
    }
  }

  &--square {
    aspect-ratio: $small-portrait-aspect-ratio;

    @include telia-breakpoint('md') {
      aspect-ratio: $square-aspect-ratio;
    }
  }

  &__section {
    margin-bottom: $telia-spacing-24;
    z-index: 10;

    &--large {
      width: 100%;

      @include telia-breakpoint('md') {
        width: 75%;
      }

      @include telia-breakpoint('xl') {
        width: 55%;
      }
    }

    &--medium {
      width: 100%;

      @include telia-breakpoint('md') {
        width: 75%;
      }

      @include telia-breakpoint('lg') {
        width: 60%;
      }
    }

    &--regular {
      width: 100%;

      @include telia-breakpoint('md') {
        min-height: 10rem;
      }

      @include telia-breakpoint('lg') {
        width: 80%;
      }
    }

    &--secondary-regular {
      width: 100%;
    }

    &--square {
      width: 100%;
    }
  }

  &__title {
    @include line-clamp($line-clamp-medium);
    padding-bottom: $telia-spacing-4;

    &--bottom-gap {
      @media (min-width: $custom-breakpoint-xsmall) {
        margin-bottom: $telia-spacing-12;
      }

      @include telia-breakpoint('md') {
        -webkit-line-clamp: $line-clamp-medium;
      }

      @include telia-breakpoint('lg') {
        -webkit-line-clamp: $line-clamp-medium;
      }

      @include telia-breakpoint('xl') {
        -webkit-line-clamp: unset;
      }
    }
  }

  &__text {
    display: none;

    @media (min-width: $custom-breakpoint-xsmall) {
      @include line-clamp($line-clamp-small);
    }

    @include telia-breakpoint('md') {
      -webkit-line-clamp: $line-clamp-medium;
    }

    @include telia-breakpoint('lg') {
      -webkit-line-clamp: $line-clamp-large;
    }
  }

  &__button {
    z-index: 2;
  }
}
