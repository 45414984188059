@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/colors/variables';

$hero-banner-height: 35rem;

.hero-banner-wrapper {
  & > * {
    word-wrap: break-word;
  }

  &--with-video {
    @include telia-breakpoint('lg') {
      height: $hero-banner-height;
    }
  }
}

.hero-banner__preamble {
  width: 100%;

  @media (min-width: $telia-breakpoint-large) {
    width: 50%;
  }
}
