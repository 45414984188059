@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

@mixin line-clamp($line-qty, $box-orient: vertical) {
  display: -webkit-box;
  -webkit-line-clamp: $line-qty;
  -webkit-box-orient: $box-orient;
  overflow: hidden;
}

@mixin custom-scrollbar(
  $height: $telia-spacing-4,
  $width: 100%,
  $track-color: $telia-gray-100,
  $thumb-color: $telia-gray-500
) {
  &::-webkit-scrollbar {
    height: $height;
    width: $width;
  }

  &::-webkit-scrollbar-track {
    background-color: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $thumb-color;
  }
}

@mixin bottom-margins {
  margin-bottom: $telia-spacing-48;

  @include telia-breakpoint('md') {
    margin-bottom: $telia-spacing-128;
  }
}
