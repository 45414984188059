@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $telia-spacing-16;
  text-align: center;

  &__title {
    @include telia-breakpoint('lg') {
      margin-top: $telia-spacing-16;
    }
  }

  &__cta {
    margin-top: $telia-spacing-16;
    margin-bottom: $telia-spacing-64;
  }
}
