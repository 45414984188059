@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

.form-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $telia-spacing-12;
  padding: $telia-spacing-24 $telia-spacing-16;
  background-color: $telia-white;

  @include telia-breakpoint('lg') {
    border-radius: $telia-spacing-16;
  }

  @include telia-breakpoint('lg') {
    padding: $telia-spacing-32 $telia-spacing-24;
  }

  &__error {
    color: $telia-red-500;
    text-align: center;
    grid-column: span 2;
  }
}

.step-button {
  & > button {
    // Updates inner Voca button component
    width: 100%;
    height: 100%;
  }

  &__text {
    display: flex;
    align-items: center;
    gap: $telia-spacing-8;
  }

  &__icon {
    color: $telia-black;
  }

  &--close {
    grid-column: span 1;
    justify-self: start;
    order: -1;
    margin-left: $telia-spacing-24;

    @include telia-breakpoint('lg') {
      grid-column: span 2;
      order: unset;
      justify-self: center;
      margin-left: 0;
    }
  }

  &--expand {
    grid-column: span 1;
    justify-self: end;
    order: -1;

    @include telia-breakpoint('lg') {
      order: unset;
      display: none;
    }
  }
}
