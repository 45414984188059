@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/breakpoints/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/borders/variables';

.report-wizard {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}