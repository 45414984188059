@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/borders/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import './extensions';
@import 'styles/transitions';

$side-modal-max-width: 330px;
$center-modal-max-width: 600px;
$center-modal-max-height: 80vh;

.base-modal-wrapper {

  &--hidden {
    display: none;
  }

  &--side {
    position: absolute;
  }

  &--center {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.base-modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1001;
  background-color: $telia-white;
  overflow: hidden;

  &--side {
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: $side-modal-max-width;
    transform: translateX(100%);
    transition: $transform-transition;
  }

  &--center {
    height: auto;
    max-height: $center-modal-max-height;
    max-width: $center-modal-max-width;
    border-radius: $telia-border-radius-16;
    box-shadow: 0px $telia-spacing-16 $telia-spacing-32 rgba(34, 34, 34, 0.16);

    @include telia-breakpoint('sm') {
      width: 90%;
    }
  }

  &--full-width {
    max-width: 100%;
  }
}

.base-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &--side {
    box-shadow: 0 $telia-spacing-2 $telia-spacing-4 $telia-gray-100;
    padding-top: $telia-spacing-8;
    padding-bottom: $telia-spacing-8;
    @extend %side-modal-horizontal-padding;
  }

  &--center {
    align-items: center;
    padding-top: $telia-spacing-16;
    padding-bottom: $telia-spacing-16;
    @extend %center-modal-horizontal-padding;
  }

  &--vertical-center {
    align-items: center;
  }
}

.base-modal-content {
  flex-grow: 1;
  overflow: auto;
}

// Right to left transition
.right-to-left-enter {
  transform: translateX(100%);
}

.right-to-left-enter-active {
  transform: translateX(0%);
  transition: $transform-transition;
}

.right-to-left-enter-done {
  transform: translateX(0%);
}

.right-to-left-exit {
  transform: translateX(0%);
}

.right-to-left-exit-active {
  transform: translateX(100%);
  transition: $transform-transition;
}
