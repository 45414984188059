@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

.step-two-form {
  &__title {
    margin-bottom: $telia-spacing-8 !important;
  }

  &__image-wrapper {
    margin-bottom: $telia-spacing-24;
  }

  textarea {
    max-width: 100%;
    max-height: 50vh;

    @include telia-breakpoint('md') {
      max-width: 200%;
    }
  }
}
