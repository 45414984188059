@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/breakpoints/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/colors/variables';
@import 'styles/mixins.scss';
@import 'styles/breakpoints.scss';

.page-level-0 {
  &__hero-banner {
    color: $telia-white;
  }

  &__top-cards-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: $telia-spacing-24;

    @media (min-width: $custom-breakpoint-medium) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__mid-section {
    background-size: cover;
    padding-bottom: $telia-spacing-48;
  }

  &__bottom-cards-wrapper {
    margin-top: $telia-spacing-64;

    @include bottom-margins();
  }

    &__bottom-cards-heading {
    margin-bottom: $telia-spacing-24;
  }
}
