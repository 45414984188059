@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/typography/mixins';
@import '@teliads/components/foundations/focus/mixins';
@import 'styles/transitions';

.mobile-header-navigation {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  &__body {
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__nav {
    display: flex;
    width: 200%;
    transition: $transform-transition;
  }

  &__current-link {
    @include telia-title-200;
    padding: $telia-spacing-20 $telia-spacing-16;
  }

  &__level1-list,
  &__level2-list {
    list-style-type: none;
    margin: 0;
    padding: $telia-spacing-16;
    width: 50%;
  }

  &__level1-list {
    &--hidden {
      visibility: hidden;
    }
  }

  &__level2-list {
    display: none;

    &--show {
      display: block;
    }
  }

  &__list-item {
    padding: $telia-spacing-16 0;

    a:link,
    a:visited,
    a:active {
      @include telia-paragraph-100;

      text-decoration: none;
      color: $telia-black;
    }
  }

  &__link {
    &:focus-visible {
      @include telia-focus-outline();
    }
  }

  &__nested-list {
    list-style-type: none;
    margin: 0;

    > li:first-child {
      padding-top: 0;
    }

    > li:last-child {
      padding-bottom: 0;
    }
  }

  &__footer {
    background: $telia-gray-50;
    padding: 0 $telia-spacing-16;
  }
}
