@import "@teliads/components/foundations/breakpoints/mixins";

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;

  &__component {
    width: 50%;

    @include telia-breakpoint('lg') {
      width: 12%;
    }
  }
}

