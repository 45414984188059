@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

$mobile-width: 1024px;

.search-container {
    position: relative;
    width: 50%;

    @media(max-width: $mobile-width) {
      width: 100%;
    }
  
    &__popover-container {
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
      width: 100%;
      background-color: $telia-white;
      box-shadow: 0 $telia-spacing-2 $telia-spacing-4 $telia-gray-100;
  
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
  
        li {
          padding: 10px;
          cursor: pointer;
  
          &:hover {
            background-color: $telia-gray-100;
          }
        }
      }
    }
  }