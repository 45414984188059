@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/borders/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import 'styles/breakpoints.scss';
@import 'styles/mixins.scss';

$line-clamp-large: 4;
$line-clamp-medium: 3;
$line-clamp-small: 2;

.info-box {
  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  padding-top: $telia-spacing-48;
  padding-bottom: $telia-spacing-48;


  &__title {
    @include line-clamp($line-clamp-medium);
    padding-bottom: $telia-spacing-4;
    margin-bottom: $telia-spacing-12;


    &--bottom-gap {
      @media (min-width: $custom-breakpoint-xsmall) {
        margin-bottom: $telia-spacing-12;
      }

      @include telia-breakpoint('md') {
        -webkit-line-clamp: $line-clamp-medium;
      }

      @include telia-breakpoint('lg') {
        -webkit-line-clamp: $line-clamp-medium;
        margin-bottom: $telia-spacing-2;
      }

      @include telia-breakpoint('xl') {
        -webkit-line-clamp: unset;
      }
    }
  }

  &__text {
    display: none;
    margin-bottom: $telia-spacing-48;

    @media (min-width: $custom-breakpoint-xsmall) {
      @include line-clamp($line-clamp-small);
    }

    @include telia-breakpoint('md') {
      -webkit-line-clamp: $line-clamp-medium;
    }

    @include telia-breakpoint('lg') {
      -webkit-line-clamp: $line-clamp-large;
    }

    &--without-margin {
      margin-bottom: 0px;
    }
  }
}
