@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/breakpoints/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/colors/variables';

.step-one-form {
  width: 100%;
  padding: $telia-spacing-32 $telia-spacing-24;
  background-color: $telia-white;
  border-radius: $telia-spacing-16;
  display: flex;
  flex-direction: column;
  gap: $telia-spacing-24;

  &__chips {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: $telia-spacing-16;

    @include telia-breakpoint('md') {
      flex-direction: row;
    }
  }

  &__date-toggle {
    align-items: flex-end;
  }

  &__radio-buttons {
    margin-top: $telia-spacing-12;
    display: flex;
    flex-direction: column;
    gap: $telia-spacing-16;
  }

  &__radio-buttons-compliance {
    display: flex;
    gap: $telia-spacing-16;
    justify-content: space-between;

    @include telia-breakpoint('md') {
      flex-direction: column;
    }
  }

  &__divider {
    margin-bottom: $telia-spacing-4;
  }

  &__radio-section {
    gap: $telia-spacing-24;
  }

  textarea {
    max-width: 100%;
    max-height: 50vh;

    @include telia-breakpoint('md') {
      max-width: 200%;
    }
  }

  telia-label {
    white-space: nowrap;
  }
}
