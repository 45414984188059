@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';

$file-upload-padding: $telia-spacing-24 $telia-spacing-16;
$file-upload-font-weight: 400;
$file-upload-font-size: 14px;

.file-upload {
  display: none;
  padding: $file-upload-padding;
  background-color: $telia-white;

  @include telia-breakpoint('lg') {
    border-radius: $telia-spacing-16;
    margin-bottom: $telia-spacing-24;
    display: block;
  }

  &--expanded {
    display: block;
  }

  input[type='file'] {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: $telia-spacing-16 0;
    overflow-y: scroll;
    max-height: 40vh;
  }

  li {
    display: flex;
    align-items: center;
    padding: $telia-spacing-8 0;
    color: $telia-black;
    font-size: $file-upload-font-size;

    font-weight: $file-upload-font-weight;
    border-bottom: 1px $telia-gray-300 solid;

    .icon {
      margin-right: $telia-spacing-24;
      color: $telia-black;
      cursor: pointer;
    }

    .file-name {
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .file-size {
      white-space: nowrap;
      margin-left: $telia-spacing-16;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }
  }

  &__max-message {
    color: $telia-black;
    font-size: $file-upload-font-size;
    font-weight: $file-upload-font-weight;
    word-wrap: break-word;
    margin: 0;
    text-align: right;

    @include telia-breakpoint('lg') {
      text-align: unset;
    }
  }

  &__error-message {
    color: $telia-red-500;
    font-size: $file-upload-font-size;
  }

  &__button {
    text-align: right;

    @include telia-breakpoint('lg') {
      text-align: unset;
    }
  }

  &__heading {
    display: none;

    @include telia-breakpoint('lg') {
      display: block;
    }
  }
}
