@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/motion/variables';
@import '@teliads/components/foundations/borders/mixins';
@import '@teliads/components/foundations/spacing/variables';

$diameter-small: 44px;
$diameter-large: 60px;
$disabled-gray: rgba(0, 0, 0, 0.1);

.carousel-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  color: $telia-gray-700;
  background-color: white;

  border-radius: 50%;
  @include telia-border(gray-700);

  width: $diameter-small;
  height: $diameter-small;

  transition: background-color $telia-duration-150 ease;

  margin-top: $telia-spacing-16;

  &:hover {
    background-color: $telia-gray-800;
  }

  &:active {
    background-color: $telia-gray-700;
  }

  &:hover,
  &:active {
    color: $telia-white;
  }

  @media (max-width: $telia-breakpoint-medium) {
    &:hover {
      background-color: white;
      color: $telia-gray-700;
    }

    &:active {
      background-color: $telia-gray-700;
      color: white;
    }
  }
}

.glide__arrow--disabled {
  pointer-events: none;
  background-color: $disabled-gray;
  color: $telia-gray-300;
  @include telia-border(gray-300);

  &:hover,
  &:active {
    background-color: $disabled-gray;
    color: $telia-gray-300;
  }
}
