@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/borders/mixins';
@import '@teliads/components/foundations/breakpoints/mixins';

.richtext-element {
  width: 100%;
  margin: $telia-spacing-24 0;
  display: block;
  padding-right: 0;

  @include telia-breakpoint('lg') {
    padding-right: 12.5%;
  }
}

.richtext-element-iframe {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
  border: none;
}

.richtext-paragraph {
  padding-right: 0;

  @include telia-breakpoint('lg') {
    padding-right: 12.5%;
  }
}
