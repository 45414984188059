@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/typography/variables';
@import '@teliads/components/foundations/shadows/variables';

.chip-choice {
  display: inline-block;
  position: relative;
  padding: calc($telia-spacing-8 + 1px) $telia-spacing-16;
  background-color: $telia-white;
  color: $telia-purple-900;
  border-radius: $telia-spacing-8;
  cursor: pointer;
  font-family: $telia-typography-telia-sans-font-family;
  font-weight: $telia-typography-weight-normal;
  font-size: 15px;
  border: none;
  box-shadow: 0px 0px 0px 1px $telia-purple-900;
  transition: box-shadow 0.1s linear;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    box-shadow: 0px 0px 0px 2px $telia-purple-900;
  }

  &:hover.selected {
    box-shadow: 0px 0px 0px 1px $telia-purple-900;
  }

  &:active {
    background-color: $telia-purple-200;
    box-shadow: 0px 0px 0px 1px $telia-purple-200;
  }

  &--selected {
    color: $telia-white;
    background-color: $telia-purple-900;
  }
}
