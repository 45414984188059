@import 'styles/animation';

$opacity-transition: opacity $animation-duration $transition-timing-function;
$transform-transition: transform $animation-duration $transition-timing-function;

// Opacity transition
.opacity-enter {
  opacity: 0;
}

.opacity-enter-active {
  opacity: 1;
  transition: $opacity-transition;
}

.opacity-exit {
  opacity: 1;
}

.opacity-exit-active {
  opacity: 0;
  transition: $opacity-transition;
}

// Horizontal slide transition
.slide-enter {
  transform: translateX(-50%);
}

.slide-enter-done {
  transform: translateX(-50%);
}
