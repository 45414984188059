@import '@teliads/components/foundations/spacing/variables';
@import '@teliads/components/foundations/breakpoints/mixins';
@import '@teliads/components/foundations/colors/variables';
@import '@teliads/components/foundations/borders/variables';

$gap: $telia-spacing-24;

.report-form {
  position: relative;
  width: 100%;
  background-color: $telia-gray-50;
  padding: $gap 0;
}

.form-right {
  --header-height: 0px;

  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 10;

  @include telia-breakpoint('lg') {
    position: sticky;
    top: var(--header-height);
    bottom: unset;
  }
}
